import SwiperGallery from '@esign/swiper-gallery';

export default function () {
  new SwiperGallery();

  let getSwiperGalleryModal = document.querySelector('.swiper-gallery');
  const observer = new MutationObserver((mutationRecords) => {
    for (let i = 0; i < mutationRecords.length; i++) {
      const record = mutationRecords[i];

      if (record.type === 'attributes') {
        const currentAriaExpanded = record.target.getAttribute('aria-expanded');
        const getIframe = getSwiperGalleryModal.getElementsByTagName('iframe')[0];

        if (currentAriaExpanded === 'false' && getIframe) {
          // stop playing video
          getIframe.src = '';
        } else if (currentAriaExpanded !== 'false' && getIframe) {
          // start playing video
          getIframe.src = getIframe.getAttribute('src');
        }
      }
    }
  });

  const getSwiperModal = setInterval(() => {
    getSwiperGalleryModal = document.querySelector('.swiper-gallery');

    if (getSwiperGalleryModal) {
      clearInterval(getSwiperModal);

      observer.observe(getSwiperGalleryModal, {
        attributes: true,
        attributeFilter: ['aria-expanded'],
        childList: false,
        characterData: false,
      });
    }
  }, 500);
}
