/* eslint-disable */
import '../../utils/waypoints';

export default function () {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');
  const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

  if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./) || isFirefox) {
    // If Internet Explorer, return version number
    Array.from(document.querySelectorAll('.aanbod-grid .property__block')).forEach((value, key) => {
      console.log(key);
      value.classList.add('loaded');
    });
  } // If another browser, return 0
  else {
    Array.from(document.querySelectorAll('.aanbod-grid .property__block')).forEach((value, key) => {
      new Waypoint({
        element: value,
        handler: function (direction) {
          value.classList.add('loaded');
          console.log(direction, key);
        },
        offset: window.innerHeight - 50,
      });
    });
  }
}
