export default function () {
  if (document.querySelector('.property-request__block') && window.innerWidth > 768) {
    let open = false;

    window.addEventListener('scroll', () => {
      document.querySelector('html').style.scrollPaddingTop = '0';
      if (window.scrollY + window.innerHeight / 2 >= document.querySelector('.property-request__block').offsetTop) {
        document.querySelector('.property-request__block').classList.add('slide-in');
        document.querySelectorAll('.js--disappear').forEach((element) => {
          element.classList.add('disappear');
        });
        if (!open) {
          setTimeout(() => {
            document.querySelector('.js-scroll-anchor').click();
          }, 500);
        }
        open = true;
      } else if (window.scrollY + window.innerHeight <= document.querySelector('.property-request__block').offsetTop) {
        document.querySelectorAll('.js--disappear').forEach((element) => {
          element.classList.remove('disappear');
        });
        document.querySelector('.property-request__block').classList.remove('slide-in');
        document.querySelector('html').style.scrollPaddingTop = '250px';

        open = false;
      }
    });
  }
}
