export default function () {
  // Remember scroll position in property overview when returning from detailpage
  window.addEventListener('unload', () => {
    const scrollPosition = window.pageYOffset;
    localStorage.setItem('scrollPosition', scrollPosition);

    if (document.documentElement.getAttribute('data-page')) {
      localStorage.setItem('comingFromPage', document.documentElement.getAttribute('data-page'));
    } else {
      localStorage.setItem('comingFromPage', null);
    }
  });

  if (
    localStorage.scrollPosition
    && document.documentElement.getAttribute('data-page') === 'properties'
  ) {
    if (localStorage.getItem('comingFromPage') === 'property-detail') {
      window.scrollTo(0, localStorage.getItem('scrollPosition'));
    }
  }
}
