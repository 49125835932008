/* eslint-disable import/no-extraneous-dependencies */
// eslint-disable-next-line import/no-extraneous-dependencies
// Plugins
import { ModalManager } from '@esign/modal';
import CookieNotification from '@esign/cookie-notification';

// Pages
import Contact from './pages/Contact';
import Nieuwland from './pages/Nieuwland';
import Team from './pages/Team';
import Home from './pages/Home';
import PropertyDetail from './pages/PropertyDetail';
import ReferenceIndex from './pages/ReferenceIndex';
import ValuationIndex from './pages/ValuationIndex';

// Components
import navigation from './components/layout/navigation';
import responsiveVideos from './components/layout/responsiveVideos';
import sliders from './components/sliders';
import wayPoints from './components/layout/wayPoints';
import timeline from './components/timeline/timeline';
import disableImages from './components/layout/disableImages';
import rememberScrollPos from './utils/rememberScrollPos';
import checkMixBlendMode from './components/layout/checkMixBlendMode';
import _hmodh from './components/_hmodh';
import scrollToTop from './components/layout/scrollToTop';
import masonryGrid from './components/layout/masonryGrid';
import skarabee from './components/skarabee';
import modalCookies from './components/modal/modalCookies';

// Vendor
import initChoices from './vendor/choicesJS';
import swiperGallery from './vendor/esign/swiperGallery';
import urlChecker from './vendor/choicesJS/urlChecker';

require('./utils/nativeConsole');
const objectFitImages = require('object-fit-images');

// Set js class
const htmlClassList = document.documentElement.classList;
htmlClassList.add('js');
htmlClassList.remove('no-js');

// eslint-disable-next-line
window.modalManager = new ModalManager();

// Layout setup
navigation();
responsiveVideos();
wayPoints();
disableImages();
rememberScrollPos();
checkMixBlendMode();
objectFitImages(
  document.querySelectorAll(
    '.flex-image img, .intro-image img, .property__block img, .reference__block-image img, .slideshow__trigger-box img, .map__wrapper img, .office__image img, .image-slide img, .ofi img, .fancybox img',
  ),
);
masonryGrid();
scrollToTop();
modalCookies();
sliders();

window.cookieNotification = new CookieNotification({
  variants: [],
  triggerBtn: '.js-cookie-notification-trigger',
  cookies: {
    marketing: true,
  },
});

// components
timeline();
_hmodh();

// vendor
initChoices();
urlChecker();

// Forms
skarabee();

// Page specific classes
const pages = {
  Contact,
  Team,
  Nieuwland,
  Home,
  PropertyDetail,
  ReferenceIndex,
  ValuationIndex,
};

const currentPage = document.documentElement.getAttribute('data-page');

if (currentPage !== 'home') {
  document.querySelectorAll('.animation').forEach((animation) => animation.classList.add('hide'));
}

if (currentPage) {
  let pageClassName = currentPage.charAt(0).toUpperCase() + currentPage.slice(1);
  pageClassName = pageClassName
    .split('-')
    .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
    .join('');

  if (pageClassName !== '' && typeof pages[pageClassName] === 'function') {
    new pages[pageClassName](); // eslint-disable-line no-new
  }
}

swiperGallery();
window.modalManager.checkModalOnPageLoad();
